import script from "./MainLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./MainLayout.vue?vue&type=script&setup=true&lang=ts"

import "./MainLayout.vue?vue&type=style&index=0&id=0ebd5426&lang=css"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import Scroll from 'quasar/src/directives/scroll/Scroll.js';
import qInstall from "../../node_modules/.pnpm/@quasar+app-webpack@3.13.2_eslint@8.57.0_pinia@2.1.7_quasar@2.16.2_vue-router@4.3.2_vue@3.4.27/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QBtnDropdown,QIcon,QBtn,QPopupProxy,QPageContainer,QDialog,QCard,QCardSection,QToolbarTitle,QSeparator,QFooter,QBanner,QBtnGroup,QTooltip});qInstall(script, 'directives', {ClosePopup,Scroll});
